import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactHlsPlayer from "react-hls-player";
import "./CurrentVideo.css";
import utils from "./utils";
import AppDownloadLinkModal from './AppDownloadLinkModal';

export default function CurrentVideo({ match }) {
  const [isLoaded, setIsLoaded] = useState(false)
  const [videoState, setVideoState] = useState({
    title: "",
    videoUrl: "",
    thumbnailUrl: "",
    description: "",
    author: {},
    viewCount: 0,
    starCount: 0,
    commentCount: 0,
    commentList: [],
    linkedProduct: {},
    videoCountOfLinkedProduct: 0,
    videoListOfLinkedProduct: [],
    videoListOfRelatedVideo: [],
    isHls: false
  }); // useState 내부에 원래 state에 해당되는 데이터를 전달한다.
  
  useEffect(() => {
    utils.setMetaTags(videoState.title, videoState.description, videoState.thumbnailUrl)
  })
  
  if (!isLoaded) {
    fetch(`https://api.greyd.app/videos/${match.params.id}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.videoUrl.substring(data.videoUrl.length - '.m3u8'.length) === '.m3u8')
          data.isHls = true
        setVideoState(data);
        setIsLoaded(true);
        
        fetch(`https://api.greyd.app/videos/${match.params.id}/comments`)
          .then((res) => res.json())
          .then((data) => {
            videoState.commentList = data
            setVideoState(videoState);
          });
      });
  }
  
  return (
    <>
      <div className="container">
        <div className="headerContainer">
          <img className="logo"
            src={'../imgs/logo180.png'}
          />
          <span className="logoTitle">
          greyd
          </span>
        </div>
          
        <div className="videoContainer">
          
          <div className="uploaderContainer">
            <img className="userProfileImage"
              src={videoState.author.profilePicUrl ?
                   videoState.author.profilePicUrl :
                   "../imgs/default-user-image.png"}
            />
            <span className="uploaderName">{videoState.author.name}</span>
          </div>
          
          <div className="videoPlayerContainer">
            { videoState.isHls ?
            <ReactHlsPlayer
                className="videoPlayer"
                url={videoState.videoUrl}
                autoplay={true}
                controls={true}
                poster={videoState.thumbnailImageUrl}
                crossOrigin={"annonymous"}
            />
            :
            <video
              className="videoPlayer"
              src={videoState.videoUrl}
              controls
              autoPlay
              poster={videoState.thumbnailImageUrl}
              crossOrigin={"annonymous"}
            >
              The browser doesn't support this video player
            </video>
            }
          </div>
          
          <div className="sectionContainer">
            <div>
              <span className="videoInfo">
                {videoState.viewCount} views
              </span>
            </div>
            
            <div>
              <span className="videoInfo">Total rating </span>
              <span className="videoRatingScore">{videoState.ratingScore}</span>
              <span className="videoInfo">({videoState.ratingCount})</span>
            </div>
          </div>
          
          <div className="sectionContainer">
            <p className="sectionTitle">Linked product</p>
            <a target="_blank" className="linkedProductLink"
              href={videoState.linkedProduct.productId ?
                    `/products/${videoState.linkedProduct.productId}` :
                    videoState.linkedProduct.externalLink ?
                    videoState.linkedProduct.externalLink :
                    '#'}>
              <div className="linkedProductContainer">
              { videoState.linkedProduct.thumbnailUrl &&
                <img
                  className="linkedProductThumbnail"
                  src={videoState.linkedProduct.thumbnailUrl}
                />
              }
                <span className="linkedProductDescription">
                  <div className="linkedProductTitle">
                    {videoState.linkedProduct.title}
                  </div>
                  <div className="linkedProductPrice">
                    { videoState.linkedProduct.discountPrice &&
                    <span className="discountPrice">${videoState.linkedProduct.discountPrice}</span>
                    }
                    { videoState.linkedProduct.price &&
                    <span className="originalPrice">${videoState.linkedProduct.price}</span>
                    }
                    { videoState.linkedProduct.discountPrice &&
                    <span className="discountRate">
                      {Math.round(100 - (videoState.linkedProduct.discountPrice / videoState.linkedProduct.price) * 100)}% ↓
                    </span>
                    }
                  </div>
                </span>
              </div>
            </a>
          </div>
                                         
         <div className="sectionContainer">
          <p className="title">{videoState.title}</p>
          <p className="description">{videoState.description}</p>
         </div>
                                       
         <div className="sectionContainer">
           <p className="sectionTitle">Comments({videoState.commentCount})</p>
           {videoState.commentList && videoState.commentList.map((comment) => (
             <div className="commentItemContainer" key={comment._id}>
               <span className="commentAuthorProfileImageContainer">
                 <img
                   className="userProfileImage"
                   src={comment.authorProfilePicUrl ?
                        comment.authorProfilePicUrl :
                        "../imgs/default-user-image.png"}
                 />
               </span>
               <span className="commentInfoContainer">
                 <div>
                   <span className="commentAuthorName">
                     {comment.authorName}
                   </span>
                   <span className="commentInfo">
                     {utils.timeSince(new Date(comment.createdAt))}
                   </span>
                 </div>
                 <div className="comment">
                   {comment.comment}
                 </div>
               </span>
             </div>
           ))}
         </div>
                                      
         { videoState.videoListOfLinkedProduct && videoState.videoListOfLinkedProduct.length > 0 &&
         <div className="sectionContainer">
           <p className="sectionTitle">Linked reviews</p>
            {videoState.videoListOfLinkedProduct.map((video) => (
              <Link to={`/videos/${video.id}`}>
                <img className="videoThumbnail" src={video.thumbnailUrl} />
              </Link>
            ))}
          </div>
          }
        </div>
      </div>
          
      <AppDownloadLinkModal />
    </>
  );
}
