import React, { useState, useEffect } from "react";
import Utils from './utils';

export default function AppstoreRedirect() {
  const redirect = () => {
    if (Utils.isAndroid()) {
      window.open('https://play.google.com/store/apps/details?id=com.arbaim.greyd', '_self');
    } else {
      window.open('https://apps.apple.com/us/app/com-arbaim-greyd/id1526096078', '_self');
    }
  };
  redirect()
  
  return (
    <></>
  )
}
