import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ImageGallery from 'react-image-gallery';
import "./CurrentProduct.css";
import "./ImageGallery.css"
import styled from "styled-components";
import utils from "./utils";
import AppDownloadLinkModal from './AppDownloadLinkModal';

const TOTAL_SLIDES = 2;

const PriceView = ({product}) => {
  if (product.discountPrice) {
    return (
      <div className="price">
        <span className="discountPrice">￦{product.discountPrice}</span>
        <span className="originalPrice">￦{product.price}</span>
        <span className="discountRate">
          {Math.round(100 - (product.discountPrice / product.price) * 100)}% ↓
        </span>
      </div>
    )
  } else {
    return (
      <div className="price">
        <span className="discountPrice">￦{product.price}</span>
      </div>
    )
  }
}

export default function CurrentProduct({ match }) {
  const [isLoaded, setIsLoaded] = useState(false)
  const [productImages, setProductImages] = useState(false)
  const [productState, setProductState] = useState({
    title: "",
    thumbnailUrl: "",
    discountPrice: 0,
    price: 0,
    videoUrl: "",
    description: "",
    attachmentList: [],
    seller: {},
    linkedVideoList: [],
    linkedVideoCount: 0,
    commentCount: 0,
    commentList: [],
    reviewList: [],
    reviewCount: 0,
    sellerOtherProductCount: 0,
    sellerOtherProductList: [],
    relatedOtherProductList: [],
  });
  
  useEffect(() => {
    utils.setMetaTags(productState.title, productState.description, productState.thumbnailUrl)
  })
    
  if (!isLoaded) {
    fetch(`https://api.greyd.app/products/${match.params.id}`)
    .then((res) => res.json())
    .then((data) => {
      setProductState(data);
      
      var images = []
      for (var i=0; i<productState.attachmentList.length; i++) {
        images.push({
          original: productState.attachmentList[i].url,
          thumbnail: productState.attachmentList[i].url
        })
      }
      setProductImages(images)
      setIsLoaded(true);
      
      fetch(`https://api.greyd.app/products/${match.params.id}/comments`)
        .then((res) => res.json())
        .then((data) => {
          productState.commentList = data
          setProductState(productState);
        });
    });
  }
  
  return (
    <>
      <div className="container">
        <div className="headerContainer">
          <img className="logo"
            src={'../imgs/logo180.png'}
          />
          <span className="logoTitle">
          greyd
          </span>
        </div>
          
        <div className="productContainer">
                                 
          <div className="sellerContainer">
            <img className="userProfileImage"
              src={productState.seller.profilePicUrl ?
              productState.seller.profilePicUrl :
              "../imgs/default-user-image.png"}
            />
            <span className="sellerName">{productState.seller.name}</span>
          </div>
          
          <div className="productImageSliderContainer">
          { productImages &&
            <ImageGallery
              items={productImages}
              showPlayButton={false}
              showFullscreenButton={false}
              renderItem={source => {
                return(
                  <img className="productImageSliderSelectedImage" src={source.original}/>
                )
              }}
              renderThumbInner={source => {
                return (
                  <img className="productImageSliderThumbnail" src={source.thumbnail} />
                )
              }}/>
          }
          </div>
          
          <div className="productTitle">
          {productState.title}
          </div>
          <PriceView
            product={productState}
          />
          { productState.ratingScore > 0 &&
          <div className="rating">
             Rating: {productState.ratingScore}
          </div>
          }
          <p className="productDescription">{productState.description}</p>
                                 
          <div className="sectionContainer">
            <p className="sectionTitle">Comments({productState.commentCount})</p>
            {productState.commentList && productState.commentList.map((comment) => (
              <div className="commentItemContainer" key={comment._id}>
                <span className="commentAuthorProfileImageContainer">
                  <img
                    className="userProfileImage"
                    src={comment.authorProfilePicUrl ?
                         comment.authorProfilePicUrl :
                         "../imgs/default-user-image.png"}
                  />
                </span>
                <span className="commentInfoContainer">
                  <div>
                    <span className="commentAuthorName">
                      {comment.authorName}
                    </span>
                    <span className="commentInfo">
                      {utils.timeSince(new Date(comment.createdAt))}
                    </span>
                  </div>
                  <div className="comment">
                    {comment.comment}
                  </div>
                </span>
              </div>
            ))}
          </div>

         <div className="sectionContainer">
           <p className="sectionTitle">Linked reviews({productState.linkedVideoCount})</p>
            {productState.linkedVideoList && productState.linkedVideoList.map((video) => (
              <Link to={`/videos/${video.id}`}>
                <img className="videoThumbnail" src={video.thumbnailUrl} />
              </Link>
            ))}
          </div>

          <div className="sectionContainer">
            <p className="sectionTitle">
              Products on sale by {productState.seller.name} ({productState.sellerOtherProductCount})
            </p>
            {productState.sellerOtherProductList && productState.sellerOtherProductList.map((product) => (
            <Link className="productListItemLink" to={`/products/${product.productId}`}>
                <div className="productListItemContainer">
                  <img className="productListItemThumbnail"
                    src={product.thumbnailUrl}
                  />
                 <p className="productListItemTitle">{product.title}</p>
                </div>
              </Link>
            ))}
          </div>
                                 
          <div className="sectionContainer">
            <p className="sectionTitle">Similar products</p>
             {productState.sellerOtherProductList && productState.sellerOtherProductList.map((product) => (
             <Link className="productListItemLink" to={`/products/${product.productId}`}>
                 <div className="productListItemContainer">
                   <img className="productListItemThumbnail"
                     src={product.thumbnailUrl}
                   />
                  <p className="productListItemTitle">{product.title}</p>
                 </div>
               </Link>
             ))}
          </div>
        </div>
      </div>
          
      <AppDownloadLinkModal />
    </>
  );
}
