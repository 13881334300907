import React, { useState, useEffect } from "react";
import { Modal } from 'antd';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import Utils from './utils';

export default function AppDownloadLinkModal() {
  const [isAppLinkModalVisible, setIsAppLinkModalVisible] = useState(Utils.isMobile());
  const showAppLinkModal = () => {
    setIsAppLinkModalVisible(true);
  };
  const handleAppLinkModalOk = () => {
    setIsAppLinkModalVisible(false);
    if (Utils.isAndroid()) {
      window.open('https://play.google.com/store/apps/details?id=com.arbaim.greyd', '_blank');
    } else {
      window.open('https://apps.apple.com/us/app/com-arbaim-greyd/id1526096078', '_blank');
    }
  };
  const handleAppLinkModalCancel = () => {
    setIsAppLinkModalVisible(false);
  };
  
  return (
    <Modal title="greyd 다운로드"
        visible={isAppLinkModalVisible}
        onOk={handleAppLinkModalOk}
        onCancel={handleAppLinkModalCancel}>
      <p>greyd 모바일앱에서 더 쾌적한 환경으로 보기</p>
    </Modal>
  )
}
