import React from 'react';
import ReactDOM from 'react-dom';
import { useParams } from 'react-router-dom';
import qs from 'qs';
import './App.css';
const PayPalButton = window.paypal.Buttons.driver('react', { React, ReactDOM });

export default function PayPal({ location }) {
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  console.log(query, window.paypal);

  const { price, currency } = query;

  function _createOrder(data, actions) {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            currency_code: currency,
            value: price,
          },
        },
      ],
    });
  }
  async function _onApprove(data, actions) {
    let order = await actions.order.capture();
    console.log(order);
    window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage(JSON.stringify(order));
    return order;
  }
  function _onError(err) {
    console.log(err);
    let errObj = {
      err: err,
      status: 'FAILED',
    };
    window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage(JSON.stringify(errObj));
  }
  return (
    <div className='PayPalButton'>
      <PayPalButton
        createOrder={(data, actions) => _createOrder(data, actions)}
        onApprove={(data, actions) => _onApprove(data, actions)}
        onCancel={() => _onError('CANCELED')}
        onError={(err) => _onError('ERROR: ' + JSON.stringify(err))}
      />
    </div>
  );
}
