import React from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import CurrentProduct from './CurrentProduct';
import CurrentVideo from './CurrentVideo';
import AppstoreRedirect from './AppstoreRedirect';
import PayPal from './Paypal';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Router>
        <>
          <div>
            <Switch>
              <Route path='/products/:id' component={CurrentProduct} />
              <Route path='/videos/:id' component={CurrentVideo} />
              <Route path='/appstore' component={AppstoreRedirect} />
              <Route path='/paypal' component={PayPal} />
              <Route
                path='/'
                component={() => {
                  window.location.href = 'https://intro.greyd.app';
                  return null;
                }}
              />
              <Route
                path='/intro'
                component={() => {
                  window.location.href = 'https://intro.greyd.app';
                  return null;
                }}
              />
            </Switch>
          </div>
        </>
      </Router>
    );
  }
}

export default App;
