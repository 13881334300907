function timeSince(date) {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
}
                           
const setMetaTags = ({
  title="greyd - Share your experience with the world",
  description="Share your experience with the world",
  imageUrl="https://greyd.app/src_ogimage.png"
}) => {    //set title
  document
    .querySelector('meta[property="og:title"]')
    .setAttribute("content", `${title}`);
  //set description
  document
    .querySelector('meta[property="og:description"]')
    .setAttribute("content", description);
  //set images
  document
    .querySelector('meta[property="og:image"]')
    .setAttribute("content", imageUrl);
  //set url
  document
    .querySelector('meta[property="og:url"]')
    .setAttribute("content", window.location.href);
};

const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

const isAndroid = () => {
  return /Android/i.test(navigator.userAgent)
}

export default {
    timeSince,
    setMetaTags,
    isMobile,
    isAndroid,
}
